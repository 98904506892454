import { graphql, useStaticQuery } from 'gatsby';
import React from "react";
import PostBox from '../PostBox/PostBox';

import "./posts.scss"

export default function Posts(props) {
    const data = useStaticQuery(query);
    const posts = data.allWpPost.edges;
    
    return (
      <div className="posts section-padding">
          <div className="container">
              <div className="row">
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                      <span className="small-header secondary-text-color">Strefa wiedzy</span>
                      <h3 className="header-h3 mt-15 mb-30 black-text-color">Poradniki ubezpieczeniowe</h3>
                      <p className="paragraph mb-60 black-text-color">Skorzystaj z wiedzy naszych specjalistów. Dowiedz się więcej o ubezpieczeniach na życie do kredytu hipotecznego i wybieraj świadomie.</p>
                  </div>
              </div>
              <div className="row display-flex">
                    {posts.map((post, index) => {
                        return <div className="col-lg-4 col-xs-12 col-sm-6 mb-30"><PostBox post={post.node} /></div>
                    })}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <a href="/poradniki" className="articles-link" title="Poradniki">Zobacz wszystkie artykuły <i className="arrow-icon"></i></a>
                </div>
              </div>
          </div>
      </div>
    );
}

const query = graphql`
{
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Poradniki"}}}}}
      limit: 9
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          content
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          poradniki {
            zajawka
          }
          slug
          dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
        }
      }
    }
}  
`


