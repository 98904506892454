import React from "react";
import { getImage } from 'gatsby-plugin-image';
import { readingTime } from "reading-time-estimator";

import "./post-box.scss"

export default function PostBox(props) {
    let post = props.post;
    const image = getImage(post.featuredImage.node.localFile);
    let excerpt = post.poradniki.zajawka;

    if (excerpt.length >= 80) {
        excerpt = excerpt.slice(0, 100).split(' ').slice(0, -1).join(' ') + '... ';
    }
    
    return (
        <div className="post-box">
            <a className="post-link" href={`/poradniki/${post.slug}/`} title={post.title}>
                <div className="image" style={{ backgroundImage: `url(${image.images.fallback.src})` }}></div>
                <div className="post-info">
                    <div className="date-time">
                        <span className="date">{post.dateGmt}</span>
                        <span className="time">{readingTime(post.content).minutes} min.</span>
                    </div>
                    <h3 className="header-h5 primary-text-color post-title mt-15">{post.title}</h3>
                    <p className="excerpt paragraph font-light black-text-color mb-0">{excerpt}</p>
                </div>
            </a>
        </div>
    );
}