import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import InfoBox from '../components/InfoBox/InfoBox'

import '../scss/pages/about-us.scss'
import Posts from '../components/Posts/Posts'
import Background from '../assets/images/about-bg.png'
import PrimaryButton from '../components/PrimaryButton/PrimaryButton'
import config from '../../config.json'

const AboutUsPage = () => {
  const data = useStaticQuery(query);
  const seo = data.wpPage.seo;
 
  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/o-nas/" />
      <div className="page-top-section">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12">
              <p className="small-header text-center mb-0 text-white">O nas</p>
              <h1 className="header-h1 text-center mt-15 mb-30 text-white">Poznajmy się lepiej!</h1>
              <p className="paragraph text-center text-white font-light mb-0">Od 2020 roku każdego dnia pomagamy setkom osób znaleźć najkorzystniejsze oferty ubezpieczeń na życie. Doradzamy, edukujemy i tworzymy narzędzia ułatwiające sprawne poruszanie się na rynku. Pomożemy Ci kupić najkorzystniejszą polisę do kredytu zupełnie zdalnie.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-us section-padding">
        <div className="container custom-container">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="box">
                  <i className="document-ico mx-auto d-block"></i>
                  <h4 className="header-h4 black-text-color text-center mt-30 mb-30">Ubezpieczenia do <br />kredytu bez tajemnic</h4>
                  <p className="paragraph black-text-color mb-30 text-center font-light">W naszym zespole działają specjaliści z ponad 10-letnim doświadczeniem. Codziennie dla naszych klientów porównują dziesiątki produktów ubezpieczeniowych.</p>
                  <p className="paragraph black-text-color mb-0 text-center font-light">Analizujemy OWU, przedstawiamy wady i zalety produktów, szukamy najlepszych i dopasowanych rozwiązań.</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="box float-end">
                  <i className="clock-ico mx-auto d-block"></i>
                  <h4 className="header-h4 black-text-color text-center mt-30 mb-30">Szanujemy i cenimy <br />Twój czas</h4>
                  <p className="paragraph black-text-color mb-30 text-center font-light">Wiemy, jak trudny bywa wybór odpowiedniego ubezpieczenia pod kredyt. Wiemy też, że wcale nie musi tak być! Sprawimy, że niczym nie będziesz się musiał przejmować.</p>
                  <p className="paragraph black-text-color mb-0 text-center font-light">Korzystając z naszych usług, możesz w jednej chwili porównać nawet pięć ofert u jednego lub kilku doradców nie wychodząc z domu.</p>
                </div>
              </div>
            </div>
        </div>
        <InfoBox />
      </div>
      <div className="offer section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3 className="header-h3 mb-30 text-white">Oferujemy dopasowane rozwiązania</h3>
              <p className="paragraph text-white mb-30 font-light">Rozmowa telefoniczna, e-mail, wideoczat – <span className="font-bold">Ty wybierasz</span> sposób konsultacji z doradcą. Podczas spotkania dowiesz się, ile może kosztować ubezpieczenie Twojego kredytu, a także <span className="font-bold">otrzymasz szczegółową analizę</span> produktów.</p>
              <p className="paragraph text-white mb-30 font-light">Gwarantujemy <span className="font-bold">nielimitowaną</span> ilość ofert – jeśli nie znajdziesz satysfakcjonującego Cię ubezpieczenia do kredytu na pierwszym spotkaniu, możesz umówić się na kolejne.</p>
              <p className="paragraph text-white mb-30 font-light">Wypełnij formularz i porozmawiajmy o najlepszym zabezpieczeniu Twojego kredytu. <span className="font-bold">Bez zobowiązań!</span></p>
              <PrimaryButton link={config.CALC_LINK} title="Porównaj oferty" icon="unbalanced-icon" />
            </div>
            <div className="col-md-5">
              <img className="bg-image" draggable={false} src={Background} alt="Oferujemy dopasowane rozwiązania" title="Oferujemy dopasowane rozwiązania" />
            </div>
          </div>
        </div>
      </div>
      <Posts />
    </Layout>
  )
}

export default AboutUsPage

const query = graphql`
  {
    wpPage(slug: {eq: "o-nas"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
  }
`
