import React from "react";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import config from '../../../config.json'

import "./info-box.scss"

export default function InfoBox() {

    return (
        <div className="container no-padding">
          <div className="row">
            <div className="col-md-12">
              <div className="info-box mt-30 mb-30">
                <h2 className="header-h4 text-white mb-0">Ubezpieczenie tańsze <span className="font-bold">nawet&nbsp;o&nbsp;60%</span></h2>
                <PrimaryButton link={config.CALC_LINK} title="Uzyskaj wycenę polisy" icon="pocket-icon" />
              </div>
            </div>
          </div>
        </div>
    );
}